import React from "react";
import ServerBox from "./ServerBox";

enum SortType {
  DefaultByNumber,
  PlayerCount,
  Map,
  ServerType,
}
enum ShowFilterOptions {
  ServerTypes,
  ServerRegion,
  ServerMaps,
  OrderBy,
}

type MyState = {
  Servers: PublicServerInfo[];
  FilteredServerTypes: string[];
  FilterServerTypes: string[];
  FilterServerRegion: string[];
  FilterServerMap: string[];
  SortBy: SortType;
  ShowFilters: ShowFilterOptions[];
};
type MyProps = {};
export class Servers extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      Servers: [],
      FilteredServerTypes: [],
      FilterServerMap: [],
      FilterServerRegion: [],
      FilterServerTypes: [],
      SortBy: SortType.DefaultByNumber,
      ShowFilters: [],
    };
  }
  componentDidMount() {
    fetch("https://api.pandahut.net/api/ServerProxy/PublicList")
      .then((result) => result.json())
      .then((result: PublicServerInfo[]) => {
        result.forEach((result) => {
          // Manually prettifying some Server Types
          if (result.ServerType === "Unturnov15") {
            result.ServerType = "Unturnov 1.5";
          }
          if (result.ServerType === "RP") {
            result.ServerType = "Roleplay";
          }
          if (result.ServerType === "CreativeArena") {
            result.ServerType = "Creative Arena";
          }
        });
        this.setState({
          Servers: result.sort((a, b) => {
            var matcha = a.ServerName.match(/(\d+)/);
            var matchb = b.ServerName.match(/(\d+)/);
            if (
              matcha != null &&
              matchb != null &&
              matcha?.length !== 0 &&
              matchb?.length !== 0
            ) {
              a.ServerID = matcha[0].toString();
              b.ServerID = matchb[0].toString();
              var match1 = Number.parseInt(matcha[0].toString());
              var match2 = Number.parseInt(matchb[0].toString());
              a.SortValue = match1;
              b.SortValue = match2;
              return match1 - match2;
            }
            return 0;
            //console.log(`We got the match1 ${match1} and the match2 ${match2}`);
          }),
        });
      });
  }
  SelectUniqueServerTypes(): string[] {
    return [...new Set(this.state.Servers.map((i) => i.ServerType))];
  }
  SelectUniqueMaps(): string[] {
    return [...new Set(this.state.Servers.map((i) => i.MapName))];
  }
  SelectUniqueRegions(): string[] {
    return [...new Set(this.state.Servers.map((i) => i.Region))];
  }
  ChangeSelectedServerType(type: string): void {
    if (this.state.FilteredServerTypes.includes(type)) {
      this.setState({
        FilteredServerTypes: this.state.FilteredServerTypes.filter(
          (e) => e !== type
        ),
      });
    } else {
      this.setState({
        FilteredServerTypes: this.state.FilteredServerTypes.concat(type),
      });
    }
  }
  ChangeSelectedServerRegion(type: string): void {
    if (this.state.FilterServerRegion.includes(type)) {
      this.setState({
        FilterServerRegion: this.state.FilterServerRegion.filter(
          (e) => e !== type
        ),
      });
    } else {
      this.setState({
        FilterServerRegion: this.state.FilterServerRegion.concat(type),
      });
    }
  }
  ChangeSelectedServerMap(type: string): void {
    if (this.state.FilterServerMap.includes(type)) {
      this.setState({
        FilterServerMap: this.state.FilterServerMap.filter((e) => e !== type),
      });
    } else {
      this.setState({
        FilterServerMap: this.state.FilterServerMap.concat(type),
      });
    }
  }
  ChangeFilterTypes(type: ShowFilterOptions): void {
    if (this.state.ShowFilters.includes(type)) {
      this.setState({
        ShowFilters: this.state.ShowFilters.filter((e) => e !== type),
      });
    } else {
      this.setState({
        ShowFilters: this.state.ShowFilters.concat(type),
      });
    }
  }
  ChangeOrderBy(input: SortType) {
    console.log(input);
    this.setState({
      SortBy: input,
    });
  }
  DealWithSorting(a: PublicServerInfo, b: PublicServerInfo): number {
    if (this.state.SortBy === SortType.DefaultByNumber) {
      if (b.SortValue === null || a.SortValue == null) {
        return 0;
      }
      return a.SortValue - b.SortValue;
    } else if (this.state.SortBy === SortType.Map) {
      var Maps = this.SelectUniqueMaps();
      return Maps.indexOf(b.MapName) - Maps.indexOf(a.MapName);
    } else if (this.state.SortBy === SortType.PlayerCount) {
      return b.PlayersOnline - a.PlayersOnline;
    } else if (this.state.SortBy === SortType.ServerType) {
      var ServerTypes = this.SelectUniqueServerTypes();
      return (
        ServerTypes.indexOf(b.ServerType) - ServerTypes.indexOf(a.ServerType)
      );
    }
    return 0;
  }
  render() {
    return (
      <div className="ReactBase">
        <div className="SelectionServerType">
          <label>
            <input
              type="checkbox"
              id="ChangeFilterOptionMaps"
              onChange={(e) =>
                this.ChangeFilterTypes(ShowFilterOptions.ServerMaps)
              }
            />
            Filter By Maps
          </label>
          <label>
            <input
              type="checkbox"
              id="ChangeFilterRegion"
              onChange={(e) =>
                this.ChangeFilterTypes(ShowFilterOptions.ServerRegion)
              }
            />
            Filter By Region
          </label>
          <label>
            <input
              type="checkbox"
              id="ChangeFilterType"
              onChange={(e) =>
                this.ChangeFilterTypes(ShowFilterOptions.ServerTypes)
              }
            />
            Filter By Server Type
          </label>
          <label>
            <input
              type="checkbox"
              id="ChangeFilterOrderBy"
              onChange={(e) =>
                this.ChangeFilterTypes(ShowFilterOptions.OrderBy)
              }
            />
            Order Servers
          </label>
        </div>
        {this.state.ShowFilters.includes(ShowFilterOptions.ServerTypes) ? (
          <React.Fragment>
            {" "}
            <div className="SelectionServerType">
              {this.SelectUniqueServerTypes().map((type) => {
                return (
                  <label>
                    <input
                      type="checkbox"
                      id={type}
                      onChange={(e) => this.ChangeSelectedServerType(type)}
                    />
                    {type}
                  </label>
                );
              })}
            </div>
          </React.Fragment>
        ) : null}
        {this.state.ShowFilters.includes(ShowFilterOptions.OrderBy) ? (
          <React.Fragment>
            {" "}
            <div className="SelectionServerType">
              <label>
                <input
                  type="radio"
                  name="OrderBy"
                  id="OrderByServerNumber"
                  onChange={(e) => this.ChangeOrderBy(SortType.DefaultByNumber)}
                />
                Order By Server Number
              </label>
              <label>
                <input
                  type="radio"
                  name="OrderBy"
                  id="OrderByServerMap"
                  onChange={(e) => this.ChangeOrderBy(SortType.Map)}
                />
                Order By Map
              </label>
              <label>
                <input
                  type="radio"
                  name="OrderBy"
                  id="OrderByServerPlayers"
                  onChange={(e) => this.ChangeOrderBy(SortType.PlayerCount)}
                />
                Order By Player Count
              </label>
              <label>
                <input
                  type="radio"
                  name="OrderBy"
                  id="OrderByServerType"
                  onChange={(e) => this.ChangeOrderBy(SortType.ServerType)}
                />
                Order By Server Type
              </label>
            </div>
          </React.Fragment>
        ) : null}
        {this.state.ShowFilters.includes(ShowFilterOptions.ServerRegion) ? (
          <React.Fragment>
            {" "}
            <div className="SelectionServerType">
              {this.SelectUniqueRegions().map((type) => {
                return (
                  <label>
                    <input
                      type="checkbox"
                      id={type}
                      onChange={(e) => this.ChangeSelectedServerRegion(type)}
                    />
                    {type}
                  </label>
                );
              })}
            </div>
          </React.Fragment>
        ) : null}
        {this.state.ShowFilters.includes(ShowFilterOptions.ServerMaps) ? (
          <React.Fragment>
            {" "}
            <div className="SelectionServerType">
              {this.SelectUniqueMaps().map((type) => {
                return (
                  <label>
                    <input
                      type="checkbox"
                      id={type}
                      onChange={(e) => this.ChangeSelectedServerMap(type)}
                    />
                    {type}
                  </label>
                );
              })}
            </div>
          </React.Fragment>
        ) : null}
        {this.state.Servers.filter(
          (e) =>
            ((this.state.FilteredServerTypes.length === 0 &&
              e.ServerType !== "Lobby") ||
              this.state.FilteredServerTypes.includes(e.ServerType) !==
                false) &&
            (this.state.FilterServerMap.length === 0 ||
              this.state.FilterServerMap.includes(e.MapName)) &&
            (this.state.FilterServerRegion.length === 0 ||
              this.state.FilterServerRegion.includes(e.Region))
        ).length === 0 ? (
          <React.Fragment>
            <div className="modal">
              <div className="header" style={{ color: "white" }}>
                No Servers Matched Your Filters.
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div className="serversbox gridnormalstretchy">
          {this.state.Servers.filter(
            (e: PublicServerInfo) =>
              ((this.state.FilteredServerTypes.length === 0 &&
                e.ServerType !== "Lobby") ||
                this.state.FilteredServerTypes.includes(e.ServerType) !==
                  false) &&
              (this.state.FilterServerMap.length === 0 ||
                this.state.FilterServerMap.includes(e.MapName)) &&
              (this.state.FilterServerRegion.length === 0 ||
                this.state.FilterServerRegion.includes(e.Region))
          )
            .sort((a: PublicServerInfo, b: PublicServerInfo) =>
              this.DealWithSorting(a, b)
            )
            .map((result: PublicServerInfo) => {
              return (
                <ServerBox
                  key={result._id}
                  ServerName={result.ServerName}
                  ServerIP={GetServerIPPretty(result.IP)}
                  ServerType={ResolveServerType(result.ServerType)}
                  ServerPort={result.Port}
                  ServerMap={result.MapName}
                  ServerVersion={result.Version}
                  ServerID={result.ServerID}
                  PlayersOnline={result.PlayersOnline}
                  Region={result.Region}
                />
              );
            })}
        </div>
      </div>
    );
  }
}
export default Servers;

function ResolveServerType(input: string): string {
  if (input === null || input === undefined) {
    return input;
  }
  var type = input.toLowerCase().trim();

  if (type.startsWith("semi-vanilla")) {
    return `Semi-Vanilla - 10x Loot | No Bulletdrop | Feast | Airdrop | TPA/Home | Old School`;
  } else if (type.startsWith("kitpvp")) {
    return `KitPvP - Start-out with 50k, Buy Kits with Bamboo, Loads of OP Kits, PvP, Economy, Shop`;
  } else if (type.startsWith("normal")) {
    return `Normal PvP Econ - Shop, Economy, PvP. Nothing Complex about it!`;
  } else if (type.startsWith("rust")) {
    return `Rust PvP - Kits, Vendors, Custom Map, Custom Config, Custom Plugins!`;
  } else if (type.startsWith("rp")) {
    return `Roleplay - No RDM/KOS, Tons of Custom Plugins, Shop, Roles, and more!`;
  } else if (type.startsWith("unturnov 1.5")) {
    return `Unturnov 1.5 - Vendors, Quests, Apartments, and more!`;
  } else if (type.startsWith("unturnov")) {
    return `Unturnov - Vendors, Quests, Apartments, and more!`;
  } else if (type.startsWith("vanilla+")) {
    return `Vanilla+ - A Near Vanilla Experience with some helpful additions like home and TPA!`;
  } else if (type.startsWith("lobby")) {
    return `Lobby -- Easily join all Pandahut Servers, chill out and play Paintball! Default location if you are in a server that restarts.`;
  } else if (type.startsWith("creative arena")) {
    return `Creative Arena -- You can use /i to spawn items, /save and /load to save and load inventories. Any item, any loadout, have fun!`;
  } else if (type.startsWith("creative")) {
    return `Creative -- You can use /i to spawn items, /v to spawn vehicles, /save and /load to save and load inventories. Any item, any loadout, have fun!`;
  }
  return input;
}

function GetServerIPPretty(IP: string): string {
  IP = IP.toLowerCase().trim();
  switch (IP) {
    case "51.81.233.200":
      return "semi.pandahut.net";
    case "213.32.111.47":
      return "eusemi.pandahut.net";
    case "51.81.51.128":
      return "vanilla3.pandahut.net";
    case "51.81.51.141":
      return "washsemi.pandahut.net";
    case "51.81.233.201":
      return "kitpvp.pandahut.net";
    case "51.81.51.130":
      return "pvp.pandahut.net";
    case "51.81.233.202":
      return "pvp2.pandahut.net";
    case "213.32.111.40":
      return "eusemi2.pandahut.net";
    case "51.81.51.132":
      return "rust.pandahut.net";
    case "51.81.51.131":
      return "rp.pandahut.net";
    case "51.81.51.133":
      return "peisemi.pandahut.net";
    case "51.81.51.134":
      return "unturnov.pandahut.net";
    case "213.32.111.41":
      return "unturnoveu.pandahut.net";
    case "213.32.111.42":
      return "kitpvpeu.pandahut.net";
    case "51.81.51.136":
      return "kitpvp2.pandahut.net";
    case "213.32.111.43":
      return "eusemielver.pandahut.net";
    case "51.81.233.203":
      return "vanilla18.pandahut.net";
    case "213.32.111.45":
      return "eusemipei.pandahut.net";
    case "213.32.111.46":
      return "eusemiwash.pandahut.net";
    default:
      return IP;
  }
}

export interface PublicServerInfo {
  _id: string;
  IP: string;
  Port: string;
  ServerName: string;
  MapName: string;
  Version: string;
  IsOnline: boolean;
  ServerType: string;
  ServerID: string;
  PlayersOnline: number;
  Region: string;
  SortValue: null | number;
}
