import React, { Fragment } from "react";
import Popup from "reactjs-popup";

type MyState = {};
type MyProps = {
  ServerName: string;
  ServerIP: string;
  ServerType: string;
  ServerPort: string;
  ServerMap: string;
  ServerVersion: string;
  ServerID: string;
  PlayersOnline: number;
  Region: string;
};
export class ServerBox extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.HideAnswer = this.HideAnswer.bind(this);
    this.state = {};
  }
  componentDidMount() {}
  HideAnswer(e: any) {
    e.preventDefault();
    e.stopPropagation();
  }
  // Extremely hacky
  convertToGuidesLink(input: string, mapName: string) {
    if (mapName === "PANDAHUT RP LARGE PEI") {
      return "RP-13-(PEI-Large)";
    }
    switch (input) {
      case "Roleplay":
        return "RP-10-(Washington)";
    }
    if (input.startsWith("Unturnov 1.5")) {
      return "Unturnov-1.5-(12,-14)";
    }
    if (input.length === 0 || input.includes(" ") === false) {
      return input;
    }
    switch (input.split(" ")[0]) {
      case "Normal":
        return "Normal-Economy";
      case "Unturnov 1.5":
        return "Unturnov-1.5-(12,-14)";
      case "Unturnov":
        return "Unturnov-1.25-(19)";
      case "Roleplay":
        return "RP-10-(Washington)";
    }
    return input.split(" ")[0];
  }

  render() {
    return (
      <div className="card show">
        <img
          src={`https://cdn.pandahut.net/Maps/${this.props.ServerMap.split(
            " "
          ).join("")}.png`}
          alt={`${this.props.ServerMap}`}
        />
        <div className="card-title">
          <h2>{this.props.ServerName}</h2>
        </div>
        <div className="card-flap">
          <div className="card-description">
            <ul className="alignLeft">
              <li>
                IP: <strong>{this.props.ServerIP}</strong> Port:{" "}
                <strong>{this.props.ServerPort}</strong>
              </li>
              <li>
                <span>Map:</span> <strong>{this.props.ServerMap}</strong>
              </li>
              <li>
                <span>Player Slots:</span> <strong>96</strong>
              </li>
              <li>
                <span>Style:</span> <strong>{this.props.ServerType}</strong>
              </li>
              <li>
                <span>Region:</span> <strong>{this.props.Region}</strong>
              </li>
              <li>
                <span>Players:</span>{" "}
                <strong>{this.props.PlayersOnline}/96</strong>
              </li>
              <li>
                <a
                  href={`https://guides.pandahut.net/${this.convertToGuidesLink(
                    this.props.ServerType,
                    this.props.ServerMap
                  )}`}
                >
                  <button className="button btn">Guide</button>
                </a>
              </li>
              <li>
                <Popup
                  trigger={<button className="button btn"> Play Now</button>}
                  className="fa fa-gamepad playnowicon"
                  modal
                  nested
                >
                  {(close: () => void) => (
                    <Fragment>
                      <div className="modal">
                        <div className="header">How to connect</div>
                        <div className="content">
                          In any Pandahut Server, do /lobby and find the server
                          in the lobby! <br /> You can also direct connect to a
                          lobby with the IP: lobby.pandahut.net and port: 27015.
                          <br />
                          You can also direct connect via IP:{" "}
                          {this.props.ServerIP} Port: {this.props.ServerPort}
                          <br /> Lastly, you can use the server browser, just
                          search Pandahut #{this.props.ServerID}{" "}
                        </div>
                      </div>
                      <div className="actions">
                        <button
                          className="button btn"
                          onClick={() => {
                            console.log("modal closed ");
                            // eslint-disable-next-line no-restricted-globals
                            close();
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </Fragment>
                  )}
                </Popup>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default ServerBox;
